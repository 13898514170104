import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';


import Verify from './pages/Verify';
import Notfound from './pages/NotFound';
import Endsession from './pages/EndSession';
import Conference from './pages/Conference';
import PrivateRoute from './PrivateRoute';
import Header from './Header';
import React, { useState } from 'react';
import Prefs from './services/Prefs';

export const PrefsContext = React.createContext({});

function App() {
  const [ prefs, setPrefs ] = useState(new Prefs());
  const prefsValue = { prefs, setPrefs };

  let check = () => {
    return (
      !window.localStorage.getItem("token") ||
      window.location.pathname === "/not-found" ||
      window.location.pathname === "/end-session"
    );
  };
  return (
    <div className="App">
      <PrefsContext.Provider value={prefsValue}>
        <Router>
          {check() ? <Header/> : ""}
          <Route path="/:id" exact component={Verify} />
          <Route path="/not-found" exact component={Notfound} />
          <Route path="/end-session" exact component={Endsession} />
          <PrivateRoute path="/" exact component={Conference} />
        </Router>
        </PrefsContext.Provider>
    </div>
  );
}

export default App;
