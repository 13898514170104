import React, {Component} from 'react';

class EndSession extends Component {
    render() {
        return(
            <div>
                Terima kasih, sesi anda telah berakhir
            </div>
        )
    }
}

export default EndSession;