import React, { Component, useContext, useEffect, useState } from "react";
import { PrefsContext } from "../App.js";
import Header from "../Header.js";
import UserService from "../services/Users.js";
import Spinner from "../Spinner.js";

const userService = new UserService();

const Conference = (props) => {
  const { prefs, _ } = useContext(PrefsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [room, setRoom] = useState("");
  const [user, setUser] = useState({ name: "", role: "UNKNOWN" });
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [canMeet, setCanMeet] = useState(false);


  let api = {};
  const domain = prefs.getValue("video-server-url");

  const handleClose = () => {
    console.log("handleClose");
  };

  const handleParticipantLeft = async (participant) => {
    console.log("handleParticipantLeft", participant);
    const data = await getParticipants();
  };

  const handleParticipantJoined = async (participant) => {
    console.log("handleParticipantJoined", participant);
    const data = await getParticipants();
  };

  const handleVideoConferenceJoined = async (participant) => {
    console.log("handleVideoConferenceJoined", participant);
    const data = await getParticipants();
  };

  const handleVideoConferenceLeft = () => {
    console.log("handleVideoConferenceLeft");
    /*
        prefs.clearValue("token");
        prefs.clearValue("currentUser");
        prefs.clearValue("role");
        */
    window.location = "/end-session";
  };

  const handleMuteStatus = (audio) => {
    console.log("handleMuteStatus", audio); // { muted: true }
  };

  const handleVideoStatus = (video) => {
    console.log("handleVideoStatus", video); // { muted: true }
  };

  const getParticipants = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  };

  // custom events
  const executeCommand = (command) => {
    api.executeCommand(command);
    if (command === "hangup") {
      prefs.clearValue("token");
      prefs.clearValue("currentUser");
      return props.history.push("/end-session");
    }

    if (command === "toggleAudio") {
      setIsAudioMuted(!isAudioMuted);
    }

    if (command === "toggleVideo") {
      setIsVideoMuted(!isVideoMuted);
    }
  };

  const startMeet = () => {
    if (isLoading == false) {
      return;
    }
    const options = {
      roomName: room,
      width: "100%",
      height: 900,
      configOverwrite: {
        notifications:[],
        doNotStoreRoom: true,
        // startVideoMuted: 0,
        startWithVideoMuted: false,
        startWithAudioMuted: false,
        enableWelcomePage: false,
        prejoinPageEnabled: false,
        disableRemoteMute: true,
        remoteVideoMenu: {
          disableKick: true,
        },
        resolution: 720,
        constraints: {
          video: {
            height: {
              ideal: 480,
              max: 480,
              min: 180,
            },
          },
        },
        videoQuality: {
          maxBitratesVideo: {
            low: 200000,
            standard: 500000,
            high: 1500000,
          },
          minHeightForQualityLvl: {
            480: "standard",
            720: "high",
          },
        },
      },
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        SHOW_JITSI_WATERMARK: false,
        TOOLBOX_ALWAYS_VISIBLE: true,
        HIDE_INVITE_MORE_HEADER: true,
        SHOW_WATERMARK_FOR_GUESTS: false,
        DEFAULT_REMOTE_DISPLAY_NAME: "New User",
        TOOLBAR_BUTTONS: ["microphone", "camera", "hangup"],
      },
      parentNode: document.querySelector("#siapdok-room"),
      userInfo: {
        displayName: user.name,
        role: user.role,
      },
    };

    console.log(options)
    api = new window.JitsiMeetExternalAPI(
      domain.replace("https://", ""),
      options
    );

    api.addEventListeners({
      readyToClose: handleClose,
      participantLeft: handleParticipantLeft,
      participantJoined: handleParticipantJoined,
      videoConferenceJoined: handleVideoConferenceJoined,
      videoConferenceLeft: handleVideoConferenceLeft,
      audioMuteStatusChanged: handleMuteStatus,
      videoMuteStatusChanged: handleVideoStatus,
    });
    setIsLoading(false);
  };

  const loadPatient = async () => {
    if (user.role !== "UNKNOWN") {
      return;
    }

    const patientID = JSON.parse(prefs.getValue("currentUser")).participant_id;
    const roomID = JSON.parse(prefs.getValue("currentUser")).room_id;
    try {
      // get user profile
      let resultUserPatient = await userService.getUserPatient({
        patientID
      });

      setUser({
        name: resultUserPatient.name,
        role: "PATIENT",
      });
      setRoom(`telekonsul-${roomID}`);
      setCanMeet(true);
    } catch (err) {
      console.log("Patient error -----------> ", err);
      clearLocalStorage(
        JSON.parse(prefs.getValue("currentUser")).participant_id
      );
    }
  };

  const clearLocalStorage = (id = "") => {
    prefs.clearValue("token");
    prefs.clearValue("currentUser");
    prefs.clearValue("role");
    window.location = `/${id}`;
  };

  const loadDoctor = async () => {
    if (user.role !== "UNKNOWN") {
      return;
    }
    try {
      const roomID = JSON.parse(prefs.getValue("currentUser")).room_id;
      // get user profile
      let resultUserDoctor = await userService.getUserDoctor({
        patientID: JSON.parse(prefs.getValue("currentUser")).participant_id,
      });

      setUser({
        name: resultUserDoctor.name,
        role: "DOCTOR",
      });
      setRoom(`telekonsul-${roomID}`);
      setCanMeet(true);
    } catch (err) {
      console.log("ERROR get doctor profile --> ", err);
      clearLocalStorage(
        JSON.parse(prefs.getValue("currentUser")).participant_id
      );
    }
  };

  useEffect(() => {
    if (window.JitsiMeetExternalAPI) {
      let role = prefs.getValue("role");
      if (role) {
        switch (role) {
          case "PATIENT":
            console.log("PATIENT role is here");
            loadPatient();
            break;
          case "DOCTOR":
            console.log("DOCTOR role is here");
            loadDoctor();
            break;
          default:
            alert("Role pengguna tidak tepat");
            break;
        }
      } else {
        // alert("Role pengguna tidak tersedia");
        console.log("Role pengguna tidak tersedia");
      }
    } else {
      alert("gagal memuat mesin tatap muka");
    }
    if (canMeet) {
      startMeet();
    }
  });

  return (
    <>
      <Header />
      {isLoading == true && (
        <div className="vertical-center center full-width">
          <Spinner />

          <div className="highlight center">
            Memasuki ruangan temu janji, mohon menunggu...
          </div>
        </div>
      )}
      <div id="siapdok-room-wrapper" className="center vertical-center">
        <div id="siapdok-room" />
      </div>
    </>
  );
};

export default Conference;
