import axios from "axios";
import { HTTP_TIMEOUT } from "../const";
import Utils from "./Utils";

const utils = new Utils()

export const API = {

    get: (path) => {
        return axios.get(path, { timeout: HTTP_TIMEOUT });
    },

    post: (path, payload) => {
        return axios.post(path, payload, { timeout: HTTP_TIMEOUT });
    },

    getAuth: (path) => {
        return axios.get(path, { headers: utils.generateHeaders(), timeout: HTTP_TIMEOUT });
    },

    postAuth: (path, payload) => {
        return axios.post(path, payload, { headers: utils.generateHeaders(), timeout: HTTP_TIMEOUT });
    }


}