class Prefs {
    setValue = (key, value) => {
        localStorage.setItem(key, value);
    }

    getValue = (key) => {
        return localStorage.getItem(key);
    }

    clearValue = (key) => {
        localStorage.removeItem(key);
    }
}

export default Prefs;