import React, {Component} from 'react';
import Config from '../src/Config';

class Header extends Component {
    render() {
        return(
            <div style={{ backgroundColor: Config.config.color }}>
                <img src={Config.config.images.logo} style={{width: Config.variant === "rscm" ? 150 : 80}}/>
            </div>
        )
    }
}

export default Header;