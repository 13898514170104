import logo from './logo-with-text.png'

const config = () => {
    return {
        images: {
            logo: logo,
        }, 
        color: "#24CDC3"
    }
}
export default config()
