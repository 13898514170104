import { API } from "./api";
import { serviceBaseURL } from "./const";

class Users {
  login = (payload) => {
    return new Promise((resolve, reject) => {
      let endpoint = `${serviceBaseURL}/api/session/weblink/verify/${payload.weblinkID}`
      if (payload.birthDate) {
        endpoint += `?dob=${payload.birthDate}`
      }
      API.get(endpoint)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  }
  getUserPatient = (payload) => {
    return new Promise((resolve, reject) => {
      API.getAuth(serviceBaseURL + "/api/patients/" + payload.patientID)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  }
  getUserDoctor = (payload) => {
    return new Promise((resolve, reject) => {
      API.getAuth(serviceBaseURL + "/api/staff/" + payload.patientID)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  }
}

export default Users;
