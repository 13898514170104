/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useContext, useState } from "react";
import moment from "moment";
import UserService from "../services/Users.js";
import Calendar from "react-calendar";
import Spinner from "../Spinner.js";
import AppSettings from "../services/AppSettings.js";
import { PrefsContext } from "../App.js";

const userService = new UserService();
const Verify = (props) => {
  const { prefs, _ } = useContext(PrefsContext);
  const hasToken =
    prefs.getValue("token") != "" &&
    prefs.getValue("token") != null;

  const translateUserCode = (data = "") => {
    let output = "";
    let getCode = data.split("-")[0];

    switch (getCode) {
      case "00":
        output = "PATIENT";
        break;
      case "01":
        output = "DOCTOR";
        break;
      case "02":
        output = "ASSISTANT";
        break;
      default:
        output = "UNKNOWN";
        break;
    }
    return output;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [birthDate, setBirthDate] = useState(new Date(2020, 11, 6));
  const [role, setRole] = useState(translateUserCode(props.match.params.id));
  const [id, setId] = useState(props.match.params.id);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const submitVerification = () => {
    let payload = {
      weblinkID: id.slice(3),
    };

    setErrorMessage('');
    if (role === "PATIENT") {
      payload.birthDate = moment(birthDate).format("YYYY-MM-DD");
    }

    userService
      .login(payload)
      .then((result) => {
        if (result && result.message && result.message.length > 0) {
          prefs.setValue("token", result.message);

          delete result.message;
          prefs.setValue("currentUser", JSON.stringify(result));
          prefs.setValue("role", role);

          const settings = new AppSettings();
          settings.getSetting("video-server-url").then((v) => {
            prefs.setValue("video-server-url", v);
            props.history.push('/');
          });
        } else {
          prefs.clearValue("token");
          prefs.clearValue("currentUser");
          prefs.clearValue("role");
        }
        setIsConfirmed(true);
        setIsConfirming(false);
        setIsLoading(false);

      })
      .catch((err) => {
        setErrorMessage(err.toString());
        console.log("ERROR --------> ", err);
        setIsConfirmed(true);
        setIsConfirming(false);
        setIsLoading(false);
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 403 ||
            err.response.status === 401)
        ) {
          alert("Tanggal lahir salah");
        }
      });
  };

  return (
    <>
      {isLoading == true && <Spinner className="center vertical-center" />}
      {isLoading == false && (
        <div style={{ marginTop: "10%" }}>
          {props.match.params.id === "not-found" ||
            props.match.params.id === "end-session" ? null : role ===
              "PATIENT" ? (
            <div>
              <p>Mohon dikonfirmasikan dulu tanggal lahir pasien</p>

              <div className="highlight center">
                Tanggal Lahir: {moment(birthDate).format("DD-MM-YYYY")}
              </div>
              {isConfirming == true && (
                <div className="calendar center padding-10">
                  <Spinner />
                </div>
              )}
              {isConfirming == false &&
                isConfirmed == true &&
                hasToken == false && (
                  <div className="calendar center padding-10">
                    <em>Mohon maaf</em>
                    <div>Tanggal lahir tidak dapat diverifikasi</div>
                    {errorMessage.indexOf('timeout') >= 0 && (
                      <div className="padding-10">Respon server agak lambat. Bisa cek koneksinya apakah OK?</div>
                    )}
                    <br />
                    <button
                      className="primary"
                      type="submit"
                      onClick={() => {
                        setIsConfirming(false);
                        setIsConfirmed(false);
                      }}
                    >
                      Coba Lagi
                    </button>
                  </div>
                )}

              {isConfirming == false && isConfirmed == false && (
                <div className="calendar center">
                  <Calendar
                    onChange={(event) => setBirthDate(event)}
                    value={birthDate}
                  />
                  <br />
                  <button
                    className="primary"
                    type="submit"
                    onClick={() => {
                      setIsConfirming(true);
                      submitVerification();
                    }}
                  >
                    Masuk
                  </button>
                </div>
              )}
            </div>
          ) : role === "DOCTOR" || role === "NURSE" ? (
            <button
              className="primary"
              type="submit"
              onClick={() => {
                setIsConfirming(true);
                submitVerification()
              }}
            >
              Masuk
            </button>

          ) : (
            "Maaf terjadi kesalahan pada tautan, silahkan kontak admin Telekonsul"
          )}
        </div>
      )}
    </>
  );
};

export default Verify;
