class Utils {
  generateHeaders = () => {
    let headers = {
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem('token')
    }
    return headers
  }
    
}

export default Utils;