import syncFetch from 'sync-fetch';

export const parseConfig = (uri, config) => {
  if (config.sites) {
    const path = new URL(uri)
    const c = config.sites[path.host] || {}
    return c
  }

  return {}
}

const config = (uri) => {
  const data = {}
  if (process.env.NODE_ENV === 'test') {
    return
  }

  const response = syncFetch(uri+"/siapdok.json", 
  {
    headers: {
      Accept: "application/json"
    }
  })
  console.log(response)
  if (response.status === 200) {
    let res = response.json()
    data.variant = res.variant || "default"
    data.apiHost = res.apiHost || ""

  } else {
    console.log("Using default configuration")
  }
  console.log(data)

  data.apiHost = data.apiHost || ""
  data.variant = data.variant || "default"
  data.config = require(`../branding/${data.variant}/config.js`).default

  return data  
}

export default config(window.location.origin)