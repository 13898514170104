import axios from "axios";
import { API } from "./api";
import { serviceBaseURL } from "./const";
import Utils from "./Utils";


class AppSettings {
  getSetting = (key) => {
    return new Promise((resolve, reject) => {
      let url = `${serviceBaseURL}/api/app-settings/${key}`
      API.getAuth(url)
          .then((response) => {
              resolve(response.data.data);
          })
          .catch((err) => {
              reject(err)
          })
      });
  }
};

export default AppSettings;