import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, ...rest }) {
  let isLoggedIn = () => {
    let token = localStorage.getItem('token');
    return (token != null && token.length > 0)
  };
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn() ? <Component {...props} /> : <Redirect to="/not-found" />
      }
    />
  );
}

export default PrivateRoute;
