import React, {Component} from 'react';

class NotFound extends Component {
    render() {
        return(
            <div>
                Maaf ruangan tidak ditemukan
            </div>
        )
    }
}

export default NotFound;